var render = function () {
  var _vm$placeholder;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-select', {
    attrs: {
      "value": _vm.agencySelect,
      "options": _vm.agencyOptions,
      "label": "agencyCode",
      "clearable": _vm.clearable,
      "disabled": _vm.disabled,
      "placeholder": (_vm$placeholder = _vm.placeholder) !== null && _vm$placeholder !== void 0 ? _vm$placeholder : _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      },
      "loading": _vm.loadingAgency
    },
    on: {
      "open": _vm.getAgencyList,
      "input": function input(val) {
        return _vm.$emit('update:agencySelect', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(_vm._s(data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }