<template lang="">
  <div>
    <v-select
      :value="agencySelect"
      :options="agencyOptions"
      label="agencyCode"
      :clearable="clearable"
      :disabled="disabled"
      :placeholder="placeholder ?? $t('placeholderSelect')"
      :reduce="val => val"
      :loading="loadingAgency"
      @open="getAgencyList"
      @input="(val) => $emit('update:agencySelect', val)"
    >
      <template #option="data">
        <span
          class="d-block font-weight-bold text-truncate text-uppercase"
        >
          <span class="font-weight-bolder">{{ data.agencyCode }}</span> <small>({{ data.agencyName }})</small>
        </span>
      </template>

      <template #selected-option="data">
        <span
          class="d-block font-weight-bold text-truncate text-uppercase"
        >
          <span class="text-info">{{ data.agencyCode }}</span> <small>({{ data.agencyName }})</small>
        </span>
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import store from '@/store'
import { apiAgencies } from '@/api'

export default {
  components: {
    vSelect: () => import('vue-select'),
  },
  props: {
    agencySelect: {
      type: [Object, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    forF1: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const agencyOptions = ref([])
    const loadingAgency = ref(false)
    const agencyDataId = computed(() => store.getters['userStore/getAgencyDataId'])

    function getAgencyList() {
      if (!agencyOptions.value.length) {
        loadingAgency.value = true
        apiAgencies
          .getChild(agencyDataId.value, {
            isActive: true, isRetail: props.forF1 ? undefined : true,
          })
          .then(response => {
            agencyOptions.value = response.data.items.filter(ag => (ag.id !== 1000000 && ag.id !== agencyDataId.value))
          })
          .finally(() => {
            loadingAgency.value = false
          })
      }
    }
    return {
      agencyOptions, loadingAgency, getAgencyList,
    }
  },
}
</script>
<style lang="">

</style>
